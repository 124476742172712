import axios from "axios";

export const SITE_KEY = "6LefhnsaAAAAAEzbkb9pQeb6trey2ruX8TWuSngT";
export const backendUrl = process.env.REACT_APP_BASE_URL;
const url = backendUrl + "/api";
export const PAY_URL = `${url}/reservations/pay/process`;

const user_details = JSON.parse(localStorage.getItem("user_details")) || {};
const token = user_details.token || "";
const headers = {
    Authorization: `Bearer ${token}`,
};

export const request = (
    link = "",
    method = "get",
    payload = {},
    isSecureCall = false,
    isBlobType = false
) => {
    return axios({
        headers: isSecureCall ? headers : {},
        method: method,
        url: `${url}${link}`,
        data: payload,
        responseType: isBlobType ? "blob" : "",
    }).catch((error) => {
        if (error.response) {
            if (error.response.status === 422) {
                let errors = error.response.data.data;
                if (typeof errors === "string") {
                    return (errors || "Please try again!");
                } else if (Array.isArray(errors)) {
                    let errorMessage = (errors || [])
                        .map((error) => `${error}\n`)
                        .join(" ");
                    return (errorMessage || "Something went wrong.");
                }
            } else if (error.response.status === 404) {
                return ("Invalid Request.");
            } else if (error.response.status === 500) {
                return ("Internal server error, please try after sometime.");
            }
        }
    });
};

export const checkValidation = (formState = {}) => {
    let errors = {};
    Object.entries(formState).map(([key, field]) => {
        errors[key] = [];
        if (field.required && !field.value) {
            errors[key].push(`${field.name} is required.`);
        }
        if (field.type === "email") {
            let pattern = new RegExp("[a-z0-9]+@[a-z0-9]+\\.[a-z]{2,3}$");
            let isValidFormat = pattern.test(field.value);
            if (!isValidFormat) {
                errors[key].push("This is invalid format.");
            }
        }
        if (field.name === "Phone") {
            let pattern = new RegExp(/^[0-9\b]+$/);
            if (!pattern.test(field.value)) {
                errors[key].push("Please enter only numbers.");
            } else if (field.value.length !== 10) {
                errors[key].push("Please enter a valid phone number.");
            }
        }
        if (errors[key].length === 0) {
            delete errors[key];
        }
        return errors[key];
    });
    return errors;
};
