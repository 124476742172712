import React from "react";
import { Grid } from "@material-ui/core";
import "./footer.css";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";

export const Footer = () => {
    return (
        <div className="footer-wrapper">
            <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                    <h4 className="footer-header-title adobe-font">
                        QUICK LINKS
                    </h4>
                    <div>
                        <a
                            href="http://theweddingbiryani.com"
                            className="footer-menu-button"
                        >
                            Home
                        </a>
                        <a
                            href="http://theweddingbiryani.com/order-online"
                            className="footer-menu-button"
                        >
                            Order Online
                        </a>
                        <a
                            href="https://www.bookyourseats.theweddingbiryani.com"
                            className="footer-menu-button"
                        >
                            Book Your Seats
                        </a>
                        <a
                            href="http://theweddingbiryani.com/terms-of-service"
                            className="footer-menu-button"
                        >
                            Terms of Service
                        </a>
                        <a
                            href="http://theweddingbiryani.com/privacy-policy"
                            className="footer-menu-button"
                        >
                            Privacy Policy
                        </a>
                        <a
                            href="http://theweddingbiryani.com/refund-policy"
                            className="footer-menu-button"
                        >
                            Refund Policy
                        </a>
                    </div>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <h4 className="footer-header-title adobe-font">
                        LOCATIONS
                    </h4>
                    <div>
                        <span className="address-content">
                            <strong>Velachery:</strong> 123/4, 100 Feet By-Pass
                            Road, Maheshwari Nagar, Velachery, Chennai, Tamil
                            Nadu 600042
                        </span>
                        <span className="address-content mt-1">
                            <strong>Navalur (OMR):</strong> 1A-1B, First Floor,
                            Rajiv Gandhi Salai, Navalur, OMR, Chennai, Tamil
                            Nadu 600130
                        </span>
                        {/* <span className="address-content">Chennai</span> */}
                        {/* <span className="address-content">8010653653</span> */}
                    </div>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <h4 className="footer-header-title adobe-font">&nbsp;</h4>
                    <div>
                        <span className="address-content">
                            <strong>Anna Nagar:</strong> AC/155, New No. 11,
                            Plot 4942-B, 6th Main Road, Anna Nagar, Chennai,
                            Tamil Nadu 600040
                        </span>
                        <span className="address-content mt-1">
                            <strong>T-Nagar (Express Counter):</strong> No.2,
                            Rajen Street, T.Nagar, Chennai, Tamil Nadu 600017
                        </span>
                    </div>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <h4 className="footer-header-title adobe-font">
                        FOLLOW US ON
                    </h4>
                    <div className="social-media-links">
                        <a
                            href={"https://www.facebook.com/theweddingbiryani"}
                            className="social-button"
                        >
                            <FacebookIcon
                                className="social-icons"
                                fontSize="default"
                            />
                        </a>
                        <a
                            href="https://www.instagram.com/twb.theweddingbiryani/?hl=en"
                            className="social-button"
                        >
                            <InstagramIcon
                                className="social-icons"
                                fontSize="default"
                            />
                        </a>
                    </div>
                    {/*<button className="feedback-button adobe-font">*/}
                    {/*    LEAVE US YOUR FEEDBACK*/}
                    {/*</button>*/}
                </Grid>
            </Grid>
        </div>
    );
};
